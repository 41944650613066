import React from "react"

const PreviewOverlay = () => {
  return (
    <div className="w-screen h-screen fixed left-0 top-0 z-50 bg-blue bg-opacity-90 flex items-center justify-center">
      <h6 className="text-white">Loading preview...</h6>
    </div>
  )
}

export default PreviewOverlay
