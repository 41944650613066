import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"

// Components
import SEO from "../../components/SEO"

// Helpers
import PREVIEW_QUERY from "../../templates/page-builder/PreviewQuery.gql"
import { fireEvent, getLanguageFromURL } from "../../utils/helpers"
import { RenderComponent } from "../../utils/componentRenderer"
import usePreviewClient from "../../components/hooks/usePreviewClient"
import PreviewOverlay from "../../components/PreviewOverlay"

// markup
const PagePreview = ({ params }) => {
  let { search } = useLocation()
  const client = usePreviewClient()
  const [previewOverlay, setPreviewOverlay] = useState(false)
  const [content, setContent] = useState({})
  const { modules, navbarTheme } = content

  useEffect(() => {
    fireEvent("nav-scheme", navbarTheme)
  }, [content])

  useEffect(() => {
    if (client) {
      setPreviewOverlay(true)
      client
        .query({
          query: PREVIEW_QUERY,
          variables: { locale: getLanguageFromURL(search), slug: params.slug === "" ? "home" : params.slug },
        })
        .then(result => {
          console.log("Preview Result: ", result)
          setContent(result.data.entry)
          setPreviewOverlay(false)
          document.documentElement.setAttribute("lang", getLanguageFromURL(search))
        })
        .catch(err => {
          console.log("Error: ", err)
        })
    }
  }, [client])

  if (!client || !content.modules) return <PreviewOverlay />

  return (
    <div className="relative">
      <SEO title="Preview" description="Preview" noindex={true} />
      {previewOverlay && <PreviewOverlay />}
      {modules.map((module, i) => RenderComponent(module, i))}
    </div>
  )
}

PagePreview.propTypes = {
  params: PropTypes.object,
}

export default PagePreview
